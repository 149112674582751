import '../stylesheets/style.scss';

// import './modules/page-load';
import Postcoder from './modules/postcoder';
import '../images';
import '../svgs';
import './modules';
import './components';

Postcoder('[data-lookup-address]');

const isTouchCapable = 'ontouchstart' in window ||
 (window.DocumentTouch && document instanceof window.DocumentTouch) ||
 navigator.maxTouchPoints > 0 ||
    window.navigator.msMaxTouchPoints > 0;
 
document.documentElement.classList.remove('no-js');
    
if (isTouchCapable) {
    document.documentElement.classList.remove('no-touchevents');
}